import {
  Typography, Grid, Box, Button,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import React, { useState } from "react";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import { emptyReduxState } from "Redux/Actions/genrateOfferPatient";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);
const IconsTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px",
  },
  tooltipPlacementTop: {
    margin: "4px 0",
  },
}))(Tooltip);

function PaymentCompletePage(props) {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const handelClose = () => {
    const keyToRemove = "transactionData";
    localStorage.removeItem(keyToRemove);
    props.history.push(`/${props.app.app.layout}/makePayment`);
  };
  const handlePrint = () => {
    window.print();
  };

  React.useEffect(() => {
    const dataFromStorage = localStorage.getItem("transactionData");
    if (dataFromStorage) {
      const parsedData = JSON.parse(dataFromStorage);
      setData(parsedData);
    }
  }, []);

  return (
    <div className="dashboardTiles" style={{ position: "relative" }}>
      <Card>
      <div style={{width:"99%", display:"flex", justifyContent:"space-between", padding:"5px"}}>
        <IconsTooltip title="Back" placement="top">
          <ArrowBackIcon fontSize="large" color="primary" onClick={handelClose}/>
        </IconsTooltip>
        <IconsTooltip title="Print" placement="top">
          <PrintIcon fontSize="large" color="primary" onClick={handlePrint}/>
        </IconsTooltip>
      </div>
        <Grid container>
          <Grid item xs={2} />
        </Grid>
        <Grid container>
          <Grid item xs={2} />
          <Grid item xs={12} align="center">
            {data?.completed === true ? (
              <CheckCircleIcon style={{ fontSize: "75px", color: "#3aae3a" }} />
            ) : (
              <CancelIcon style={{ fontSize: "75px", color: "#ff0a0a" }} />
            )}
          </Grid>
          <Grid item xs={2} />
        </Grid>
        <Box>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "16px",
                color: "#3a57a7",
                fontFamily: "Source Sans Pro",
              }}
            >
              <div align="center">
                <div align="center">
                  {" "}
                  {data?.completed === true ? "Thank you" : " Payment Failed"}
                </div>
                <br />

                <div>
                  {"Your payment of "}
                  <b style={{ fontSize: "22px" }}>${data?.amount}</b> with
                  transaction Id.{" "}
                  <b style={{ fontSize: "22px" }}>{data?.transactionId}</b> has{" "}
                  {data?.completed === true
                    ? "been processed successfully."
                    : "failed. Please try again."}{" "}
                  <br />
                  <br />
                </div>
              </div>
            </Grid>

            <Grid container spacing="auto">
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Account Number
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {data?.accountNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Status
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {data?.transactionStatus}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
            </Grid>
            <Grid container spacing="auto">
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Recipient Name
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {data?.recipientTitle}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Time (UTC)
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{ marginTop: "0px" }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {moment.utc(data?.timestamp).format("MM/DD/YYYY HH:mm:ss")}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
            </Grid>
            <Grid container spacing="auto">
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
              <Grid
                item
                style={{
                  marginTop: "0px",
                  borderBottom: "1px solid rgba(0,0,0,0.32)",
                }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Amount ({data?.currency})
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "0px",
                  borderBottom: "1px solid rgba(0,0,0,0.32)",
                }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {data?.amount}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "0px",
                  borderBottom: "1px solid rgba(0,0,0,0.32)",
                }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderHading}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyLabel}>
                    Txn ID
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                style={{
                  marginTop: "0px",
                  borderBottom: "1px solid rgba(0,0,0,0.32)",
                }}
                lg={2}
                md={2}
                sm={6}
                xs={6}
                sx={{ mt: 2 }}
                className={classes.girdBorderValue}
              >
                <Box className={classes.customBoxEWay}>
                  <Typography className={classes.customTypographyValue}>
                    {data?.transactionId}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={2} md={2} sm="auto" xs="auto" />
            </Grid>
            {data?.fee && (
              <Grid container spacing="auto">
                <Grid item lg={2} md={2} sm="auto" xs="auto" />
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderHading}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyLabel}>
                      Fee ({data?.currency})
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderValue}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyValue}>
                      {data?.fee}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderHading}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyLabel}>
                      {data?.feeTransactionStatus === "Completed"
                        ? "Fee Txn ID"
                        : "Fee Status"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderValue}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyValue}>
                      {data?.feeTransactionStatus === "Completed"
                        ? data?.feeTransactionId
                        : data?.feeTransactionStatus}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm="auto" xs="auto" />
              </Grid>
            )}
            {data?.refund && (
              <Grid container spacing="auto">
                <Grid item lg={2} md={2} sm="auto" xs="auto" />
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderHading}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyLabel}>
                      Refunded ({data?.currency})
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderValue}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyValue}>
                      {data?.refund}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderHading}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyLabel}>
                      {data?.refundTransactionStatus === "Completed"
                        ? "Refund Txn ID"
                        : "Refund Status"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  style={{
                    marginTop: "0px",
                    borderBottom: "1px solid rgba(0,0,0,0.32)",
                  }}
                  lg={2}
                  md={2}
                  sm={6}
                  xs={6}
                  sx={{ mt: 2 }}
                  className={classes.girdBorderValue}
                >
                  <Box className={classes.customBoxEWay}>
                    <Typography className={classes.customTypographyValue}>
                      {data?.refundTransactionStatus === "Completed"
                        ? data?.refundTransactionId
                        : data?.refundTransactionStatus}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} md={2} sm="auto" xs="auto" />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                fontSize: "16px",
                color: "#3a57a7",
                fontFamily: "Source Sans Pro",
              }}
            >
              <div align="center">
                <div>
                  <p>You may close this window.</p>
                  <br />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </div>
  );
  // }
}

PaymentCompletePage.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = (app) => ({
  userData: app.userDetails,
  app: app,
  formData: app,
  sendMoneyData: app.genrateOfferPatient.sendMoneyData,
});

export default connect(mapStateToProps, {
  emptyReduxState,
})(PaymentCompletePage);
